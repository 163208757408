<template>
  <div class="page-404">
    <p class="back"><strong>{{second}}</strong> 秒后返回 <router-link to="/">首页</router-link> ...</p>
    <p><a @click="this.$router.back()" style="cursor: pointer;">返回上一页</a></p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      second: 10 // 倒计时秒
    }
  },
  mounted () {
    setInterval(() => {
      --this.second
      if (this.second === 0) {
        this.$router.push({
          path: '/'
        })
      }
    }, 1000)
  },
  methods: {
  }
}
</script>

<style>
.page-404 {
  width: 600px;
  margin: 0 auto;
  height: 500px;
  background: url('/images/404.png') no-repeat;
  padding: 30px;
}
.back {
  margin-top: 250px;
  color: #999;
}
</style>
